<template>
  <div>
    <div class="total-info">
      <div class="total-item blue-item">
        <span>设备总数：</span>
        <span>{{ statisticsInfo.deviceCount || 0 }}</span>
      </div>
      <div class="total-item green-item">
        <span>在线设备：</span>
        <span>{{ statisticsInfo.onlineCount || 0 }}</span>
      </div>
      <div class="total-item gray-item">
        <span>离线设备：</span>
        <span>{{ statisticsInfo.offlineCount || 0 }}</span>
      </div>
      <div class="total-item orange-item">
        <span>异常设备：</span>
        <span>{{ statisticsInfo.abnormalCount || 0 }}</span>
      </div>
      <div class="total-item red-item">
        <span>正在出租：</span>
        <span>{{ statisticsInfo.cargoWayCount || 0 }}</span>
      </div>
      <div class="total-item gray-item">
        <span>缺床单：</span>
        <span>{{ statisticsInfo.lackBedspreadCount || 0 }}</span>
      </div>
    </div>
    <div class="table-page-title">
      <div class="fl">
        <el-form :model="searchForm" class="search-form" :inline="true">
          <el-form-item label="" prop="imei">
            <el-input class="small-input" v-model="searchForm.imei" placeholder="设备imei"></el-input>
          </el-form-item>
          <el-form-item label="" prop="name">
            <el-input class="small-input" v-model="searchForm.name" placeholder="设备名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="departmentName">
            <el-input class="small-input" v-model="searchForm.departmentName" placeholder="医院名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="departmentName">
            <el-input class="small-input" v-model="searchForm.positionName" placeholder="楼层"></el-input>
          </el-form-item>
          <el-form-item label="" prop="onlineStatus">
            <el-select clearable class="small-input" v-model="searchForm.onlineStatus" placeholder="在线状态">
              <el-option
                  v-for="item in onlineStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="deviceStatus">
            <el-select clearable class="small-input" v-model="searchForm.deviceStatus" placeholder="可用状态">
              <el-option
                  v-for="item in deviceStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!--<el-form-item label="" prop="deviceType">
            <el-select clearable class="small-input" v-model="searchForm.deviceType" placeholder="设备类型">
              <el-option
                  v-for="item in deviceTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>-->
        </el-form>
      </div>
      <div class="fl search-form-btn">
        <el-button class="fl" size="small" type="primary" @click="search()">搜 索</el-button>
        <el-button class="fl" size="small" @click="reset()">重 置</el-button>
      </div>
<!--      <div class="ft">-->
<!--        <el-button size="small" type="primary" @click="goAddDevice">添加设备</el-button>-->
<!--      </div>-->
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="医院名称"
            prop="department"
            align="center"
            header-align="center"
            show-overflow-tooltip="true"
            width="200"
        >
          <template slot-scope="scope">
            <span class="link_word" @click="searchByDepartment(scope.row.id)">{{ scope.row.department }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="楼层/位置"
            prop="position"
            align="center"
            width="150"
            show-overflow-tooltip="true"
            header-align="center"
        >
          <template slot-scope="scope">
            <span class="link_word" @click="searchByPosition(scope.row.position)">{{ scope.row.position || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="id"
            align="center"
            header-align="center"
            width="200"
            prop="id">
          <template slot-scope="scope">
            <span class="link_word" @click="goDetail(scope.row)">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="设备imei"
            align="center"
            header-align="center"
            show-overflow-tooltip="true"
            width="180"
            :formatter="commonFormatter"
            prop="imei">
        </el-table-column>
        <el-table-column
                label="设备名称"
                align="center"
                header-align="center"
                show-overflow-tooltip="true"
                :formatter="commonFormatter"
                width="180"
                prop="name">
        </el-table-column>
        <!--            prop="name"-->
        <el-table-column
            label="类型"
            prop="deviceType"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.deviceType === 0 ? '陪护床' : scope.row.deviceType === 1 ? '轮椅' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="是否在线"
            prop="isOnline"
            width="100"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.onlineStatus === 0 ? '离线' : scope.row.onlineStatus === 1 ? '在线' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="是否可用"
            prop="deviceStatus"
            width="100"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.deviceStatus === 0 ? '禁用' : scope.row.deviceStatus === 1 ? '可用' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="主货道"
            width="80"
            :formatter="commonFormatter"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.mayRentOut ? scope.row.mayRentOut : '0' }}/{{ scope.row.amount ? scope.row.amount : '0' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="出租中"
            prop="rentOut"
            width="80"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="异常货道"
            align="center"
            width="120"
            header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.anomalyNumber ? scope.row.anomalyNumber : 0 + scope.row.anomalyNumberTwo ? scope.row.anomalyNumberTwo : 0 }}个异常</span>
          </template>
        </el-table-column>
        <el-table-column
            label="副货道库存"
            width="100"
            prop="inventory"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            width="250"
            fixed="right"
            align="center"
            header-align="center"
            label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="goDetail(row)">查看详情</el-button>
            <el-button type="text" @click="getTableData">刷新状态</el-button>
            <el-button type="text" @click="goOrder(row.id)">设备订单</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
import {setMenuList} from "@/assets/js/common";

export default {
  name: "DeviceList",
  mixins: [tableUse],
  computed:{
    departmentName(){
      return this.$route.params.departmentName
    }
  },
  data() {
    return {
      searchForm: {
        imei:'',
        name: '',
        departmentName:'',
        positionName:'',
        onlineStatus: '',
        deviceStatus: '',
        deviceType:0,
        id:''
      },
      onlineStatusList:[
        {
          value:0,
          label:'离线'
        },
        {
          value:1,
          label: '在线'
        }
      ],
      deviceStatusList:[
        {
          value:0,
          label:'禁用'
        },
        {
          value:1,
          label:'可用'
        }
      ],
      deviceTypeList:[
        {
          value:0,
          label:'陪护床'
        },
        {
          value:1,
          label:'轮椅'
        }
      ],
      tableData: [
      ],
      statisticsInfo:{}
    }
  },
  methods: {
    reset() {
      this.searchForm = {
        imei:'',
        name: '',
        departmentName:'',
        positionName:'',
        onlineStatus: '',
        deviceStatus: ''
      }
      this.search();
    },
    searchByDepartment(departmentId){
      this.searchForm = {
        imei:'',
        name: '',
        departmentName:'',
        positionName:'',
        onlineStatus: '',
        deviceStatus: ''
      }
      this.searchForm.id = departmentId;
      this.search();
    },
    searchByPosition(position){
      this.searchForm = {
        imei:'',
        name: '',
        departmentName:'',
        positionName:'',
        onlineStatus: '',
        deviceStatus: ''
      }
      this.searchForm.positionName = position;
      this.search();
    },
    goAddDevice() {
      setMenuList({
        path: '/addDevice',
        name: '添加设备'
      })
      this.$router.push({name: 'AddDevice'})
    },
    goDetail(row) {
      setMenuList({
        path: '/deviceDetail',
        name: '设备详情',
        query:{id:row.id}
      })
      this.$router.push({name: 'DeviceDetail',query:{id:row.id}})
    },
    goOrder(id){
      this.$router.push({name: 'OrderList',params:{deviceId:id}})
    },
    getStatisticsInfo(){
      this.$axios.get('/sys-api/device-api/deviceCount',{deviceType:0},(res) => {
        if(res.code === '100'){
          this.statisticsInfo = res.data;
        }
      })
    },
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize: this.pageSize
      }
      if (this.searchForm.id) formData.id = this.searchForm.id
      if(this.searchForm.name) formData.name = this.searchForm.name;
      if(this.searchForm.imei) formData.imei = this.searchForm.imei;
      if(this.searchForm.departmentName) formData.departmentName = this.searchForm.departmentName;
      if(this.searchForm.positionName) formData.positionName = this.searchForm.positionName;
      if(this.searchForm.onlineStatus || this.searchForm.onlineStatus === 0) formData.onlineStatus = this.searchForm.onlineStatus;
      if(this.searchForm.deviceStatus || this.searchForm.deviceStatus === 0) formData.deviceStatus = this.searchForm.deviceStatus;
      if(this.searchForm.deviceType || this.searchForm.deviceType ===0) formData.deviceType = this.searchForm.deviceType
      this.$axios.post("/sys-api/device-api/devicePage",formData,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    }
  },
  mounted() {
    if(this.departmentName) this.searchForm.departmentName = this.departmentName;
    this.getStatisticsInfo();
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">

</style>
